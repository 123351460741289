<template>
  <div class="elv-report-transaction-drawer-memo">
    <h4>{{ t('report.memo') }}</h4>
    <el-input
      v-model="memo"
      :autosize="{ minRows: 2, maxRows: 5 }"
      maxlength="1000"
      type="textarea"
      :placeholder="`${t('report.addMemo')}...`"
      class="elv-report-transaction-drawer-memo-textarea"
      @change="onMemoChange"
    />

    <el-upload
      ref="uploadMemoRef"
      class="elv-report-transaction-drawer-memo-upload"
      drag
      multiple
      :limit="5"
      :file-list="fileList"
      :headers="{ Authorization: authorization }"
      :action="`${VITE_API_URL}/entity/${entityId}/${uploadApiPath}/uploadAttachment`"
      :before-upload="beforeUploadMemo"
      :on-exceed="onUploadExceed"
      @error="onUploadError"
      @success="onUploadSuccess"
    >
      <div :class="{ 'elv-report-transaction-drawer-memo-upload-content': fileList.length }">
        <SvgIcon name="source-upload" class="elv-report-transaction-drawer-memo-upload__icon" width="32" height="32" />
        <div class="elv-report-transaction-drawer-memo-upload__text">{{ t('common.uploadAttachments') }}</div>
      </div>

      <template #file="{ file }">
        <div>
          <SvgIcon :name="memoIcon(file?.ext ?? file?.response?.data?.ext)" width="16" height="16" />
          <span @click="onDownloadMemo(file?.url ?? file?.response?.data?.url)">{{ file.name }}</span>
        </div>
        <SvgIcon
          name="sources-delete"
          width="14"
          height="14"
          class="elv-report-transaction-drawer-memo-upload-delete"
          @click="onOpenDeleteConfirm(file?.response?.data ?? file)"
        />
      </template>
    </el-upload>
    <ElvMessageBox
      ref="deleteMemoPopConfirmRef"
      :confirm-button-text="t('button.delete')"
      :cancel-button-text="t('button.cancel')"
      :title="t('title.deleteAttachment')"
      :loading="deleteLoading"
      @onConfirmEvent="onConfirmDelete"
      @onCancelEvent="onCloseConfirm"
    >
      <template #content>
        <span class="elv-confirm-disconnect-info__title">{{
          t('message.deleteAttachmentInfo', { fileName: attachmentData?.name })
        }}</span></template
      >
    </ElvMessageBox>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import { downloadFile } from '@/lib/download'
import type { UploadRawFile, UploadProps } from 'element-plus'
import { transactionFilterSaveType } from '@/config/transactions'

const props = defineProps({
  currentData: {
    type: Object,
    required: true
  },
  memoType: {
    type: String,
    default: transactionFilterSaveType.TRANSFER
  }
})

const { t } = useI18n()
const route = useRoute()
const { cookies } = useCookies()
const { VITE_API_URL } = import.meta.env

const memo = ref('')
const uploadMemoRef = ref()
const attachmentData = ref<any>()
const deleteLoading = ref(false)
const deleteMemoPopConfirmRef = useTemplateRef('deleteMemoPopConfirmRef')
const fileList = ref<UploadRawFile[]>([])
const uploadApiPath = ref('transaction')

const emit = defineEmits(['onMemoChange', 'onAttachmentsChange'])

const memoHandlerType = Object.freeze([
  {
    type: transactionFilterSaveType.TRANSFER,
    apiPath: 'transaction',
    findKey: 'transactionAttachmentId'
  },
  {
    type: transactionFilterSaveType.GAIN_LOSS,
    apiPath: 'gainLoss',
    findKey: 'gainLossAttachmentId'
  },
  {
    type: transactionFilterSaveType.TRADE,
    apiPath: 'trade',
    findKey: 'tradeAttachmentId'
  }
])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const authorization = computed(() => {
  return `Bearer ${cookies.get('elv-app-token')}`
})

const currentMenoType = computed(() => {
  return memoHandlerType.find((item) => item.type === props.memoType) || memoHandlerType[0]
})

const memoIcon = computed(() => {
  return (type: string) => {
    const imgType = [
      'png',
      'jpg',
      'jpeg',
      'gif',
      'bmp',
      'webp',
      'svg',
      'ico',
      'tif',
      'tiff',
      'jfif',
      'pjpeg',
      'pjp',
      'avif',
      'apng',
      'svgz',
      'dib',
      'heif',
      'heic',
      'jpe',
      'jif',
      'jfi'
    ]
    if (imgType.includes(type)) {
      return 'memo-image'
    }
    if (type === 'pdf') {
      return 'memo-pdf'
    }
    return 'memo-file'
  }
})

const onUploadError: UploadProps['onError'] = (error) => {
  const err = JSON.parse(error.message)
  ElMessage.error(err?.data?.message)
}

const onUploadExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 5, you selected ${files.length} files this time, add up to ${
      files.length + uploadFiles.length
    } totally`
  )
}

const beforeUploadMemo: UploadProps['beforeUpload'] = (rawFile) => {
  if (rawFile.size / 1024 / 1024 > 100) {
    ElMessage.error('The file size can not exceed 100m!')
    return false
  }
  return true
}

const onMemoChange = () => {
  emit('onMemoChange', memo.value)
}

const onAttachmentsChange = () => {
  emit('onAttachmentsChange', fileList.value)
}

const onUploadSuccess: UploadProps['onSuccess'] = (response) => {
  fileList.value.push(response.data)
  onAttachmentsChange()
}

const onCloseConfirm = () => {
  deleteMemoPopConfirmRef.value?.onCheckMessageBoxDialog()
}

const onOpenDeleteConfirm = (attachment: any) => {
  attachmentData.value = attachment
  deleteMemoPopConfirmRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDelete = () => {
  deleteLoading.value = true
  if (attachmentData.value[currentMenoType.value?.findKey]) {
    const { [currentMenoType.value?.findKey]: attachmentId } = attachmentData.value
    fileList.value = fileList.value.filter(
      (item: any) => item[currentMenoType.value?.findKey] && item[currentMenoType.value?.findKey] !== attachmentId
    )
    deleteLoading.value = false
    uploadMemoRef.value?.handleRemove((file: any) => {
      return (
        file?.response?.data[currentMenoType.value?.findKey] &&
        file?.response?.data[currentMenoType.value?.findKey] === attachmentId
      )
    })
    attachmentData.value = null
    onCloseConfirm()
    onAttachmentsChange()
  }
}

const onDownloadMemo = (url: string) => {
  downloadFile(url)
}

watch(
  () => props.currentData,
  (newVal: any) => {
    nextTick(() => {
      memo.value = newVal?.memo ?? ''
      uploadApiPath.value = memoHandlerType.find((item) => item.type === props.memoType)?.apiPath ?? 'transaction'
      if (props.memoType === transactionFilterSaveType.GAIN_LOSS) {
        fileList.value = newVal?.attachments && newVal?.attachments.length ? cloneDeep(newVal?.attachments) : []
      } else if (props.memoType === transactionFilterSaveType.TRADE) {
        fileList.value = newVal?.attachments && newVal?.attachments.length ? cloneDeep(newVal?.attachments) : []
      } else {
        fileList.value =
          newVal?.transactionAttachmentList && newVal?.transactionAttachmentList.length
            ? cloneDeep(newVal?.transactionAttachmentList)
            : []
      }
    })
  }
)

defineExpose({ fileList, memo })
</script>

<style lang="scss" scoped>
.elv-report-transaction-drawer-memo {
  width: 100%;
  min-height: 190px;
  background-color: #f9fafb;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;

  h4 {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .elv-report-transaction-drawer-memo-content {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
}
</style>
<style lang="scss">
.elv-report-transaction-drawer-memo-textarea.el-textarea {
  margin-bottom: 8px;

  .el-textarea__inner {
    padding: 8px 16px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

.elv-report-transaction-drawer-memo-upload {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  .el-upload-dragger {
    border-width: 0px;
    background-color: transparent;
    width: 336px;
    height: fit-content;
    padding: 0px;

    > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      width: 100%;
      min-height: 80px;
      border: 1.5px dashed #dde1e6;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      background-color: transparent;

      &:hover {
        border-color: #1753eb;

        .elv-report-transaction-drawer-memo-upload__icon {
          fill: #1753eb;
        }

        .elv-report-transaction-drawer-memo-upload__text {
          color: #636b75;
        }
      }

      .elv-report-transaction-drawer-memo-upload__icon {
        fill: #aaafb6;
        transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        overflow: initial;
      }

      .elv-report-transaction-drawer-memo-upload__text {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #aaafb6;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &.elv-report-transaction-drawer-memo-upload-content {
        flex-direction: row;
        justify-content: flex-start;
        height: 34px;
        border-width: 0px;
        padding: 8px 0;
        margin-top: 8px;
        min-height: fit-content;

        .elv-report-transaction-drawer-memo-upload__icon {
          width: 16px;
          height: 16px;
          fill: #7597eb;
        }

        .elv-report-transaction-drawer-memo-upload__text {
          font-size: 14px;
          line-height: 18px;
          margin-left: 8px;
          color: #2f63eb;
        }
      }
    }
  }

  .el-upload-list.el-upload-list--text {
    margin: 0px;

    .el-upload-list__item {
      height: 34px;
      width: 100%;
      border-bottom: 1px solid #edf0f3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 8px;
      box-sizing: border-box;

      &:last-child {
        margin-bottom: 0px;
      }

      > div {
        display: flex;
        align-items: center;

        span {
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #1e2024;
          margin-left: 4px;
          cursor: pointer;
        }
      }

      .elv-report-transaction-drawer-memo-upload-delete {
        cursor: pointer;
        fill: #838d95;
        overflow: initial;
        transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          fill: #1e2024;
        }
      }
    }
  }
}
</style>
